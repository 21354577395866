import React, { FC } from 'react';
import { Marker } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { MtsBadge } from '@mts-ds/components-react';
import Typography from '../../../components_/Typography';
import MarkerTooltip from '../../Main/Map/components/MarkerTooltip';
import moment from 'moment';
import { divIcon } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { ReactComponent as StartMarker } from '../../../assets_/icons/startMarker.svg';
import { ReactComponent as FinishMarker } from '../../../assets_/icons/finishMarker.svg';

const useStyles = makeStyles(() => ({
  time: {
    paddingTop: '10px',
    paddingBottom: '10px'
  }
}));

interface IProps {
  points: any;
}

const StartAndFinishMarker: FC<IProps> = ({ points }) => {
  const classes = useStyles();
  const iconStart = divIcon({
    className: undefined,
    iconSize: undefined,
    iconAnchor: [15, 33],
    html: renderToString(<StartMarker />)
  });

  const iconFinish = divIcon({
    className: undefined,
    iconSize: undefined,
    iconAnchor: [15, 33],
    html: renderToString(<FinishMarker />)
  });
  return (
    <>
      <Marker
        position={{ lat: points[0].lat, lng: points[0].lon }}
        icon={iconStart}
        noRemove
        key={'start'}
      >
        <MarkerTooltip>
          <Grid>
            <MtsBadge size="s">Старт</MtsBadge>
            <Typography className={classes.time} variant="p2Bold">{`${moment(points[0].time).format(
              'L HLTS'
            )}`}</Typography>
            <Typography variant="p3Regular">{`Широта ${points[0].lat}`}</Typography>
            <Typography variant="p3Regular">{`Долгота ${points[0].lon}`}</Typography>
            {/*<Typography variant="p3Regular">{`Высота ${points[0].location.alt}`}</Typography>*/}
          </Grid>
        </MarkerTooltip>
      </Marker>
      <Marker
        position={{ lat: points[points.length - 1].lat, lng: points[points.length - 1].lon }}
        icon={iconFinish}
        noRemove
        key={'finish'}
      >
        <MarkerTooltip>
          <Grid>
            <MtsBadge size="s">Финиш</MtsBadge>
            <Typography className={classes.time} variant="p2Bold">{`${moment(
              points[points.length - 1].time
            ).format('L HLTS')}`}</Typography>
            <Typography variant="p3Regular">{`Широта ${points[points.length - 1].lat}`}</Typography>
            <Typography variant="p3Regular">{`Долгота ${points[points.length - 1].lon}`}</Typography>
            {/*<Typography variant="p3Regular">{`Высота ${points[points.length - 1].location.alt}`}</Typography>*/}
          </Grid>
        </MarkerTooltip>
      </Marker>
    </>
  );
};

export default StartAndFinishMarker;
