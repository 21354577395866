import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { paths } from '../Root/paths';

import Content from '../../components_/Content';

import Tabs from './Tabs';

import UsersList from './Users/List';
import UsersForm from './Users/Form';
import DepartamentsList from './Departaments/List';
import DepartamentsForm from './Departaments/Form';

const Staff: FC = () => {
  return (
    <Switch>
      {/* Формы */}
      <Route path={[paths.staff.users.create, paths.staff.users.edit()]} component={UsersForm} />
      <Route
        path={[
          paths.staff.departaments.create,
          paths.staff.departaments.edit(),
          paths.staff.departaments.copy()
        ]}
        component={DepartamentsForm}
      />

      {/* Списки */}
      <Route path={paths.staff.root}>
        <Content title="Персонал">
          <Tabs />
          <Switch>
            <Route exact path={paths.staff.root} component={UsersList} />
            <Route exact path={paths.staff.departaments.root} component={DepartamentsList} />
          </Switch>
        </Content>
      </Route>
    </Switch>
  );
};

export default Staff;
