import React, { FC } from 'react';

import { IProps as INotificationProps } from '.';

import ContentFilter from '../../components_/Content/ContentFilter';
import FormRow from '../../components_/Form/FormRow';
import FormCol from '../../components_/Form/FormCol';
import Dropdown from '../../components_/Fields/Dropdown';
import Button from '../../components_/Fields/Button';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import checkFilter from '../../utils/checkFilter';
import InputWithDate from '../../components_/Fields/DatePicker/inputWithDate/inputWithDate';

interface IProps {
  data: INotificationProps['data'];
  filter: any;
  onChange: any;
  onReset: () => void;
  disabled: boolean;
}

const Filter: FC<IProps> = ({ data, filter, onChange, onReset, disabled }) => {
  const { users, notificationTypes, notificationTargets } = data;
  const { notificationType, notificationTarget, employeesList, from, to } = filter;
  const { isShowReset } = checkFilter(filter);

  const userOptions = users.map(({ userId, fio }) => ({ id: userId, name: fio }));
  const notificationTypeOptions = notificationTypes.map(({ class: id, descr }) => ({
    id,
    name: descr
  }));
  const notificationTargetOptions = notificationTargets.map(({ target, descr }) => ({
    id: target,
    name: descr
  }));

  return (
    <ContentFilter>
      <FormRow>
        <FormCol>
          <Dropdown
            name="notificationType"
            label="Тип событий"
            placeholder="Выберите значение"
            options={notificationTypeOptions}
            value={notificationType}
            onMtsChange={onChange}
            disabled={disabled}
          />
        </FormCol>
        <FormCol>
          <Dropdown
            name="notificationTarget"
            label="Цель"
            placeholder="Выберите значение"
            options={notificationTargetOptions}
            value={notificationTarget}
            onMtsChange={onChange}
            disabled={disabled}
          />
        </FormCol>
        <FormCol>
          <Dropdown
            name="employeesList"
            label="Кому"
            placeholder="Выберите значение"
            options={userOptions}
            value={employeesList}
            onMtsChange={onChange}
            disabled={disabled}
          />
        </FormCol>
        <FormCol>
          {/*<BaseInput name="from" label="Дата от" type="date" value={from} onChange={onChange} />*/}
          <InputWithDate
            name="from"
            label="Дата от"
            value={from}
            onChange={onChange}
            maxDate={new Date()}
            disabled={disabled}
          />
        </FormCol>
        <FormCol>
          {/*<BaseInput name="to" label="Дата до" type="date" value={to} onChange={onChange} />*/}
          <InputWithDate
            name="to"
            label="Дата до"
            value={to}
            onChange={onChange}
            maxDate={new Date()}
            disabled={disabled}
          />
        </FormCol>
        {isShowReset && (
          <FormCol xs={12}>
            <Button color={'ghost' as ButtonColors} onClick={onReset}>
              Сбросить фильтры
            </Button>
          </FormCol>
        )}
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
