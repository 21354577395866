import React, { FC, useEffect } from 'react';
import 'leaflet-rotatedmarker';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';

import { iRootState, Dispatch } from '../../../store';
import { ReactComponent as CameraSvg } from '../../../assets_/icons/cameraMarker.svg';
import { ReactComponent as InfrastructureSvg } from '../../../assets_/icons/infrastructure.svg';
import { ICamera, ICoordXY } from '../../../models/types';
import { getColor } from '../../../theme';
import { paths } from '../../Root/paths';
import RotatedMarker from './RotatedMarker';

import CameraMarkerListItemTooltip from './CameraMarkerListItemTooltip';

const useStyles = makeStyles(() => ({
  icon: ({ hidden }: IStyleProps) => ({
    color: hidden ? getColor('constant-greyscale-500') : getColor('constant-blueberry-light'),
    fontSize: 32,
    width: '1em',
    height: '1em'
  })
}));

const mapState = (state: iRootState) => ({
  activeItem: state.infrastructureCameras.item,
  office: state.infrastructureOffices.item,
  isGeo: state.infrastructureMap.layers.isGeo
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    focus: d.infrastructureCameras.focus
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

interface IStyleProps {
  hidden?: boolean;
  edit?: boolean;
}

type IProps = {
  item: ICamera | null | undefined;
} & IStyleProps &
  connectedProps;

const CameraMarkerListItem: FC<IProps> = ({
  item,
  hidden,
  edit,
  activeItem,
  focus,
  office,
  isGeo
}) => {
  const classes = useStyles({ hidden });
  const history = useHistory();

  const { id, rotationAngle, location = { lat: 0, lon: 0, coordinateX: 0, coordinateY: 0 } } =
    item || {};

  const position: ICoordXY = isGeo
    ? [location.lat, location.lon]
    : [location.coordinateY, location.coordinateX];

  useEffect(() => {
    if (activeItem && activeItem.id === id) {
      focus(position);
    }
  }, [activeItem]);

  const handleClick = () => {
    history.push(paths.map.office.cameras.show(id));
  };

  const renderIcon = edit ? (
    <InfrastructureSvg className={classes.icon} />
  ) : (
    <CameraSvg className={classes.icon} />
  );

  const icon = divIcon({
    className: undefined,
    iconSize: undefined,
    iconAnchor: [15, 15],
    html: item ? renderToString(renderIcon) : ''
  });

  return (
    <RotatedMarker
      position={position}
      icon={icon}
      rotationAngle={rotationAngle}
      rotationOrigin="center"
      onClick={handleClick}
      noRemove
    >
      {item && <CameraMarkerListItemTooltip item={item} />}
    </RotatedMarker>
    // <Marker position={position} icon={icon} onClick={handleClick} noRemove>
    //   {item && <CameraMarkerListItemTooltip item={item} />}
    // </Marker>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(CameraMarkerListItem);
