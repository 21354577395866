/**
 * Сервис для работы с носимыми устройствами
 */

import axios from 'axios';

import { IId } from '../models/types';
import { pageSize } from '../constants/pagination';

export interface IGetAllParams {
  fullTextSearch?: string;
  pageIndex: number;
}

export interface IUpdateOrCreateBody {
  id: IId;
  number: string;
  classId: IId;
  model: string;
  invent: string;
  serial: string;
  mappingType: string;
  userId: IId | null;
  bestBefore: string | null;
}

const url = '/protection/wearable';

const DeviceService = {
  get: (id: IId) => axios.get(url, { params: { id } }),
  getAll: (params: IGetAllParams) => axios.get(`${url}s`, { params: { pageSize, ...params } }),
  updateOrCreate: (body: IUpdateOrCreateBody) => axios.post(`${url}`, body),
  unbind: (id: IId) => axios.post(`/protection/unbind?id=${id}`),
  delete: (id: IId) => axios.delete(url, { params: { id } })
};

export default DeviceService;
