import React, { FC } from 'react';

import { InputSizes } from '@mts-ds/components/dist/types/components/input/enums/sizes.enum';
import { TextareaSizes } from '@mts-ds/components/dist/types/components/textarea/enums/sizes.enum';
import { DropdownSizes } from '@mts-ds/components/dist/types/components/dropdown/enums/sizes.enum';

import FormGroup from '../../../../../../components_/Form/FormGroup';
import FormRow from '../../../../../../components_/Form/FormRow';
import FormCol from '../../../../../../components_/Form/FormCol';
import FormDoubleField from '../../../../../../components_/Form/FormDoubleField';
import FormColorPicker from '../../../../../../components_/Form/FormColorPicker';
import FormTimePicker from '../../../../../../components_/Form/FormTimePicker';
import FormInput from '../../../../../../components_/Form/FormInput';
import FormTextarea from '../../../../../../components_/Form/FormTextarea';
import FormDropdown from '../../../../../../components_/Form/FormDropdown';

import { hoursStep, minutesStep } from '../../../../../../constants/timeSteps';
import { required, size } from '../../../../../../utils/validation';

import { IProps as IFormProps } from './Form';

interface IProps {
  data: IFormProps['data'];
}

const FormInfo: FC<IProps> = ({ data }) => {
  const { dangers, protections } = data;

  return (
    <FormGroup title="Информация о зоне" size="s">
      <FormRow>
        <FormCol>
          <FormColorPicker name="mapColor" label="Цвет зоны на карте (необязательно)" />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormInput
            name="name"
            label="Название"
            placeholder="Введите название зоны"
            size={'s' as InputSizes}
            description="Допустимое количество символов: 20"
            useFieldConfig={{ validate: value => required(value) || size(value, 20) }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormTextarea
            name="descr"
            label="Краткое описание (необязательно)"
            placeholder="Введите краткое описание зоны"
            size={'s' as TextareaSizes}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDropdown
            name="dangerClassId"
            label="Класс опасности"
            placeholder="Выберите класс опасности"
            size={'s' as DropdownSizes}
            options={dangers}
            useFieldConfig={{ validate: required }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDoubleField
            label="Время работы зоны (необязательно)"
            separator="-"
            left={<FormTimePicker name="workStart" />}
            right={<FormTimePicker name="workEnd" />}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDoubleField
            label="Разрешённое время нахождения в зоне (необязательно)"
            separator=":"
            left={
              <FormDropdown
                name="allowedHours"
                placeholder="Часы"
                size={'s' as DropdownSizes}
                options={hoursStep}
              />
            }
            right={
              <FormDropdown
                name="allowedMinutes"
                placeholder="Минуты"
                size={'s' as DropdownSizes}
                options={minutesStep}
              />
            }
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDropdown
            name="protectionsList"
            label="Обязательные СИЗ (необязательно)"
            placeholder="Выберите СИЗ"
            size={'s' as DropdownSizes}
            options={protections}
            multiple
          />
        </FormCol>
      </FormRow>
    </FormGroup>
  );
};

export default FormInfo;
