import React, { FC, useState } from 'react';
import { CircleMarker } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '../../../components_/Typography';
import MarkerTooltip from '../../Main/Map/components/MarkerTooltip';
import { getColor } from '../../../theme';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  time: {
    paddingTop: '10px',
    paddingBottom: '10px'
  }
}));

interface IProps {
  point: any;
}

const HoverMarkerPoint: FC<IProps> = ({ point }) => {
  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles();
  const markerOptions = isHovering
    ? {
        radius: 6,
        weight: 3
      }
    : {
        radius: 1,
        weight: 6
      };
  return (
    <>
      <CircleMarker
        onmouseover={() => setIsHovering(true)}
        onmouseout={() => setIsHovering(false)}
        {...markerOptions}
        color={getColor('constant-blackberry-light')}
        fillColor={'white'}
        fillOpacity={1}
        center={{ lat: point.lat, lng: point.lon }}
      >
        <MarkerTooltip>
          <Grid>
            {/*<MtsBadge size="s">Финиш</MtsBadge>*/}
            <Typography className={classes.time} variant="p2Bold">
              {point.leaveTime
                ? `${moment(point.time).format('L HLTS')} - ${moment(point.leaveTime).format(
                    'HLTS'
                  )}`
                : `${moment(point.time).format('L HLTS')}`}
            </Typography>
            <Typography variant="p3Regular">{`Широта ${point.lat}`}</Typography>
            <Typography variant="p3Regular">{`Долгота ${point.lon}`}</Typography>
            {/*<Typography variant="p3Regular">{`Высота ${point.location.alt}`}</Typography>*/}
          </Grid>
        </MarkerTooltip>
      </CircleMarker>
    </>
  );
};

export default HoverMarkerPoint;
