import React, { FC, useState, useEffect } from 'react';
import { useField } from 'react-final-form';

import { IId } from '../../../../models/types';

import { defaultValues } from './Form';

const FormController: FC = () => {
  const [prevLeadId, setPrevLeadId] = useState();

  const {
    input: { value: leadId, onChange: onChangeLeadId }
  } = useField('leadId');

  const {
    input: { value: employeesList, onChange }
  } = useField<IId[]>('employeesList');

  useEffect(() => {
    let payload = [...employeesList];

    if (prevLeadId) {
      payload = payload.filter(item => item !== prevLeadId);
    }

    if (leadId && !payload.includes(leadId)) {
      payload = [...payload, leadId];
    }

    onChange(payload);

    setPrevLeadId(leadId);
  }, [leadId]);

  useEffect(() => {
    if (!employeesList.includes(leadId)) {
      onChangeLeadId(defaultValues.leadId);
    }
  }, [employeesList]);

  return null;
};

export default FormController;
