import React, { FC, useState } from 'react';
import { connect } from 'react-redux';

import { iRootState } from '../../../../store';
import getSearchedData from '../../../../utils/getSearchedData';

import DrawerAction from '../../../../components/Drawer/DrawerAction';
import DrawerContent from '../../../../components_/Drawer/DrawerContent';
import DrawerContentList from '../../../../components_/Drawer/DrawerContentList';
import DrawerContentListSearch from '../../../../components_/Drawer/DrawerContentListSearch';
import DrawerContentItemUser from '../../../../components_/Drawer/DrawerContentItemUser';
import EmptyState from '../../../../components_/EmptyState';
import { paths } from '../../../Root/paths';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  users: state.infrastructureUsers.users
});

type connectedProps = ReturnType<typeof mapState>;

type IProps = connectedProps;

const Users: FC<IProps> = ({ map, users }) => {
  const { name: mapName } = map;

  const [search, setSearch] = useState<string>('');

  const handleChangeSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const searchedUsers = getSearchedData(users, ['lastname', 'name'], search);
  const searchedUsersLength = (searchedUsers || []).length;

  return (
    <>
      <DrawerAction toUrl={paths.map.office.root} />
      <DrawerContent title={mapName}>
        <DrawerContentList title="Сотрудники" count={searchedUsersLength}>
          <DrawerContentListSearch
            placeholder="Найти сотрудника"
            value={search}
            onMtsChange={handleChangeSearch}
          />
          {searchedUsers && (
            <>
              {searchedUsers.map(user => (
                <DrawerContentItemUser key={user.id} user={user} isClickable />
              ))}
              {!searchedUsersLength && <EmptyState variant="emptyRequest" />}
            </>
          )}
        </DrawerContentList>
      </DrawerContent>
    </>
  );
};

const withConnect = connect(mapState);

export default withConnect(Users);
