import React, { FC } from 'react';

import { IEmployeesList } from '../../../../models/types';
import getFullName from '../../../../utils/getFullName';

import Table from '../../../../components_/Table';
import TableColLink from '../../../../components_/Table/TableColLink';
import TableColText from '../../../../components_/Table/TableColText';
import TableActionGroup from '../../../../components_/Table/TableActionGroup';
import TableActionButton from '../../../../components_/Table/TableActionButton';

export interface IProps {
  list: IEmployeesList[];
  onClickEdit: any;
  onClickDelete: any;
}

const List: FC<IProps> = ({ list, onClickEdit, onClickDelete }) => {
  const rows = list.map(item => ({
    items: {
      fullName: (
        <TableColText onClick={onClickEdit(item)}>
          <TableColLink>{item.fullName}</TableColLink>
        </TableColText>
      ),
      post: <TableColText>{item.post ? item.post.name : null}</TableColText>,
      department: <TableColText>{item.department ? item.department.name : null}</TableColText>,
      deviceId: <TableColText>{item.deviceId}</TableColText>,
      deviceCharge: <TableColText>{item.deviceCharge}</TableColText>,
      actions: (
        <TableActionGroup>
          <TableActionButton icon="edit" tooltip="Редактировать" onClick={onClickEdit(item)} />
          <TableActionButton icon="bin" tooltip="Удалить" onClick={onClickDelete(item)} />
        </TableActionGroup>
      )
    }
  }));

  const cols = [
    {
      title: 'ФИО',
      field: 'fullName'
    },
    {
      title: 'Должность',
      field: 'post'
    },
    {
      title: 'Подразделение',
      field: 'department'
    },
    {
      title: 'ID устройства',
      field: 'deviceId'
    },
    {
      title: 'Заряд батареи',
      field: 'deviceCharge'
    },
    {
      title: '',
      field: 'actions'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
