import React, { FC, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';
import { makeStyles } from '@material-ui/core/styles';
import { MtsAvatar } from '@mts-ds/components-react';
import TopTypography from '@top-ui/core/lib/TopTypography';
import HeaderMenu from '../HeaderMenu';
import { paths } from '../../../routes/Root/paths';
import { Link as LinkRouter } from 'react-router-dom';
import { useAsync } from '../../../hooks';
import Spinner from '../../Spinner';
import Button from '../../Fields/Button';

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    logout: d.auth.onLogout,
    getUser: d.user.getUser
  };
};

const mapState = (state: iRootState) => ({
  webSSO: state.auth.webSSO,
  isAuth: state.auth.isAuth,
  user: state.user
});

const useStyles = makeStyles(({ spacing, colors, getColor }) => ({
  root: {
    marginRight: spacing(-1),
    '&:hover': {
      cursor: '	pointer',
      '& path': { fill: getColor('brand') }
    },
    verticalAlign: 'middle'
  },
  paper: {
    width: 220
  },
  avatar: {
    verticalAlign: 'middle'
  },
  badge: {
    border: `2px solid ${colors.white}`,
    padding: `0 ${spacing(0.5)}px`
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    marginTop: 'auto',
    '&:hover': {
      cursor: 'pointer',
      color: colors.mtsRed
    }
  },
  name: {
    padding: '24px 0',
    textTransform: 'unset',
    color: colors.darkBlueberry,
    '&:hover': {
      color: colors.lightBlueberry
    }
  },
  button: {
    backgroundColor: '#e30611;',
    color: '#FFF',
    margin: '0 auto 24px auto',
    width: '128px',
    display: 'block',
    '&:hover': {
      backgroundColor: 'rgba(212, 6, 16, 1)'
    }
  }
}));

type connectedProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;

export type IProps = connectedProps;

const HeaderProfile: FC<IProps> = ({ logout, webSSO, user, isAuth, getUser }) => {
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getUser);
  const { username, name, lastname, secondname } = user;
  const rootRef = useRef<any>();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const isOpen = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (webSSO) {
      (() => {
        const widget = new (window as any).profileWidgetApp({
          target: rootRef.current,
          props: {
            disableRedirectToLogin: true,
            isB2C: false,
            supportLink: 'https://support.mts.ru/mts-unit-dispatcher',
            logoutEndpoint: () => {
              logout();
            }
          }
        });

        /*widget.$on('profileUnauthorized', () => {
        if (!isDevelopment) {
          window.location.replace(webSsoConstants.loginUrl);
        }
      });*/
      })();
    } else {
      runGetItem();
    }
  }, []);
  const isLoading = isLoadingGetItem;

  if (webSSO) {
    return <div ref={rootRef} />;
  } else {
    return (
      <>
        {isLoading && <Spinner />}
        {!isLoading && isAuth && (
          <>
            <span className={classes.root} onClick={handleOpen}>
              <MtsAvatar size={44} className={classes.avatar} />
            </span>
            <HeaderMenu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              classes={{
                paper: classes.paper
              }}
            >
              <>
                <LinkRouter
                  className={classes.link}
                  to={paths.staff.users.edit(username)}
                  onClick={handleClose}
                >
                  <TopTypography
                    align="center"
                    className={classes.name}
                    variant="button"
                    component="div"
                  >
                    {lastname} {name} {secondname}
                  </TopTypography>
                </LinkRouter>
              </>
              <Button className={classes.button} onClick={logout}>
                Выйти
              </Button>
            </HeaderMenu>
          </>
        )}
      </>
    );
  }
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(HeaderProfile);
