/**
 * Сервис для работы с картами
 */

import axios from 'axios';

import { ICoordXYZW, IId } from '../models/types';

export interface IFilterParams {
  officeId?: IId;
}

export interface IUpdateAreaBody {
  id: IId;
  mapId: IId;
  name: string;
  descr: string;
  dangerClassId: IId | null;
  mapColor: string;
  workDurationMinutes: number;
  protectionsList: IId[];
  employeesList: IId[];
}

export interface IUpdateOfficeBody {
  id: IId | null;
  name: string;
  address: string;
  lat: string;
  lon: string;
  maps: Array<{
    id: IId;
    name: string;
  } | null>;
}

export interface IUpdateMapBody {
  id: IId;
  contentId: IId | null;
  name: string;
  officeId: IId;
  descr?: string | null;
  bbox?: ICoordXYZW | null;
  gbox?: ICoordXYZW | null;
  position?: ICoordXYZW | null;
  heightGround?: number | null;
  file?: File | null;
}

const url = '/map';

const MapsService = {
  // get: (id: IId) => axios.get(`${url}/${id}`),
  get: (id: IId) => axios.get(`/v0/map/${id}`),
  getAll: (params: IFilterParams = {}) => axios.get(`${url}/list`, { params }),
  getArea: (id: IId) => axios.get(`${url}/area/${id}`),
  getAreas: () => axios.get(`${url}/area`),
  getDangers: () => axios.get(`${url}/area/dangerClasses`),
  getOffice: (id: IId) => axios.get(`/v0/places/${id}`),
  getOffices: () => axios.get(`/v0/places`),
  updateOrCreateOffice: (body: IUpdateOfficeBody) => axios.post(`/v0/places`, body),
  getMapImage: (id: IId) =>
    axios
      .get(`/map/image?mapId=${id}`, {
        responseType: 'arraybuffer'
      })
      .then(
        response =>
          'data:' +
          response.headers['content-type'] +
          ';base64,' +
          Buffer.from(response.data, 'binary').toString('base64')
      ),
  updateOrCreateArea: (body: IUpdateAreaBody) => axios.post(`${url}/area`, body),
  updateOrCreateMap: (body: IUpdateMapBody) => axios.post(`${url}`, body),
  updateOrCreateMapImage: (body: FormData) => axios.post(`${url}/updateImage`, body),
  deleteMap: (id: IId) => axios.delete(`${url}`, { params: { mapId: id } }),
  deleteArea: (id: IId) => axios.delete(`${url}/area`, { params: { areaId: id } }),
  deleteOffice: (id: IId) => axios.delete(`/v0/places/${id}`)
};

export default MapsService;
