import { ReactComponent as ArrowRight } from './arrowRight.svg';
import { ReactComponent as ArrowDown } from './arrowDown.svg';
import { ReactComponent as ArrowUp } from './arrowUp.svg';
import { ReactComponent as Battery } from './battery.svg';
import { ReactComponent as BatteryLow } from './batteryLow.svg';
import { ReactComponent as Bin } from './bin.svg';
import { ReactComponent as Box } from './box.svg';
import { ReactComponent as BubbleChart } from './bubbleChart.svg';
import { ReactComponent as Business } from './business.svg';
import { ReactComponent as CheckMark } from './checkMark.svg';
import { ReactComponent as Circle } from './circle.svg';
import { ReactComponent as CircleMarker } from './circleMarker.svg';
import { ReactComponent as Company } from './company.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as CropFree } from './cropFree.svg';
import { ReactComponent as Desktop } from './desktop.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Equalizer } from './equalizer.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as ErrorGray } from './errorGray.svg';
import { ReactComponent as ErrorOutline } from './errorOutline.svg';
import { ReactComponent as FavoriteBorder } from './favoriteBorder.svg';
import { ReactComponent as Heart } from './heart.svg';
import { ReactComponent as Hills } from './hills.svg';
import { ReactComponent as Infrastructure } from './infrastructure.svg';
import { ReactComponent as IntersectingBubbleChart } from './intersectingBubbleChart.svg';
import { ReactComponent as Label } from './label.svg';
import { ReactComponent as MarkerDrag } from './markerDrag.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as Notifications } from './notifications.svg';
import { ReactComponent as PanTool } from './panTool.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Polygon } from './polygon.svg';
import { ReactComponent as PowerSettings } from './powerSettings.svg';
import { ReactComponent as Question } from './question.svg';
import { ReactComponent as Schedule } from './schedule.svg';
import { ReactComponent as Shape } from './shape.svg';
import { ReactComponent as Shield } from './shieldExclamation.svg';
import { ReactComponent as ShieldQuestion } from './shieldQuestion.svg';
import { ReactComponent as ShieldCrossedOut } from './shieldCrossedOut.svg';
import { ReactComponent as Shortcut } from './shortcut.svg';
import { ReactComponent as Smartphone } from './smartphone.svg';
import { ReactComponent as Texture } from './texture.svg';
import { ReactComponent as Timer } from './timer.svg';
import { ReactComponent as TrendingDown } from './trendingDown.svg';
import { ReactComponent as Unlink } from './unlink.svg';
import { ReactComponent as WarningAmber } from './warningAmber.svg';
import { ReactComponent as Whatshot } from './whatshot.svg';
import { ReactComponent as ZoomIn } from './zoomIn.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as ArrowsExpandLeft } from './arrowsExpandLeft.svg';
import { ReactComponent as CompressRight } from './compressRight.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Replay } from './replay.svg';
import { ReactComponent as Translate } from './translate.svg';
import { ReactComponent as Scale } from './scale.svg';
import { ReactComponent as EnteringDangerZone } from './enteringDangerZone.svg';
import { ReactComponent as LeaveDangerZone } from './leaveDangerZone.svg';
import { ReactComponent as SoundOn } from './soundOn.svg';
import { ReactComponent as SoundOff } from './soundOff.svg';
import { ReactComponent as Charging } from './charging.svg';
import { ReactComponent as BatteryLowAlert } from './batteryLowAlert.svg';
import { ReactComponent as SOS } from './sos.svg';
import { ReactComponent as Fall } from './fall.svg';
import { ReactComponent as ImmobilizedFall } from './immobilizedFall.svg';
import { ReactComponent as CameraDrag } from './CameraDrag.svg';
import { ReactComponent as Camera } from './Camera.svg';
import { ReactComponent as Pin } from './pin.svg';
import { ReactComponent as PlaceMarkerDrag } from './placeMarkerDrag.svg';
import { ReactComponent as Layers } from './layers.svg';
import { ReactComponent as StartMarker } from './startMarker.svg';
import { ReactComponent as FinishMarker } from './finishMarker.svg';

export type IIcon =
  | 'arrowRight'
  | 'arrowDown'
  | 'arrowUp'
  | 'battery'
  | 'batteryLow'
  | 'bin'
  | 'box'
  | 'bubbleChart'
  | 'business'
  | 'checkMark'
  | 'circle'
  | 'circleMarker'
  | 'company'
  | 'copy'
  | 'cropFree'
  | 'desktop'
  | 'download'
  | 'edit'
  | 'equalizer'
  | 'error'
  | 'errorGray'
  | 'errorOutline'
  | 'favoriteBorder'
  | 'heart'
  | 'hills'
  | 'infrastructure'
  | 'intersectingBubbleChart'
  | 'label'
  | 'markerDrag'
  | 'minus'
  | 'notifications'
  | 'panTool'
  | 'phone'
  | 'plus'
  | 'polygon'
  | 'powerSettings'
  | 'question'
  | 'schedule'
  | 'shape'
  | 'shield'
  | 'shieldQuestion'
  | 'shieldCrossedOut'
  | 'shortcut'
  | 'smartphone'
  | 'texture'
  | 'timer'
  | 'trendingDown'
  | 'unlink'
  | 'warningAmber'
  | 'whatshot'
  | 'zoomIn'
  | 'lock'
  | 'arrowsExpandLeft'
  | 'compressRight'
  | 'info'
  | 'replay'
  | 'translate'
  | 'scale'
  | 'enteringDangerZone'
  | 'leaveDangerZone'
  | 'soundOn'
  | 'soundOff'
  | 'charging'
  | 'batteryLowAlert'
  | 'sos'
  | 'fall'
  | 'immobilizedFall'
  | 'cameraDrag'
  | 'camera'
  | 'pin'
  | 'placeMarkerDrag'
  | 'layers'
  | 'startMarker'
  | 'finishMarker';

type IIcons = Record<IIcon, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>;

const icons: IIcons = {
  arrowRight: ArrowRight,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  battery: Battery,
  batteryLow: BatteryLow,
  bin: Bin,
  box: Box,
  bubbleChart: BubbleChart,
  business: Business,
  checkMark: CheckMark,
  circle: Circle,
  circleMarker: CircleMarker,
  company: Company,
  copy: Copy,
  cropFree: CropFree,
  desktop: Desktop,
  download: Download,
  edit: Edit,
  equalizer: Equalizer,
  error: Error,
  errorGray: ErrorGray,
  errorOutline: ErrorOutline,
  favoriteBorder: FavoriteBorder,
  heart: Heart,
  hills: Hills,
  infrastructure: Infrastructure,
  intersectingBubbleChart: IntersectingBubbleChart,
  label: Label,
  markerDrag: MarkerDrag,
  minus: Minus,
  notifications: Notifications,
  panTool: PanTool,
  phone: Phone,
  plus: Plus,
  polygon: Polygon,
  powerSettings: PowerSettings,
  question: Question,
  schedule: Schedule,
  shape: Shape,
  shield: Shield,
  shieldQuestion: ShieldQuestion,
  shieldCrossedOut: ShieldCrossedOut,
  shortcut: Shortcut,
  smartphone: Smartphone,
  texture: Texture,
  timer: Timer,
  trendingDown: TrendingDown,
  unlink: Unlink,
  warningAmber: WarningAmber,
  whatshot: Whatshot,
  zoomIn: ZoomIn,
  lock: Lock,
  arrowsExpandLeft: ArrowsExpandLeft,
  compressRight: CompressRight,
  info: Info,
  replay: Replay,
  translate: Translate,
  scale: Scale,
  enteringDangerZone: EnteringDangerZone,
  leaveDangerZone: LeaveDangerZone,
  soundOn: SoundOn,
  soundOff: SoundOff,
  charging: Charging,
  batteryLowAlert: BatteryLowAlert,
  sos: SOS,
  fall: Fall,
  immobilizedFall: ImmobilizedFall,
  cameraDrag: CameraDrag,
  camera: Camera,
  pin: Pin,
  placeMarkerDrag: PlaceMarkerDrag,
  layers: Layers,
  startMarker: StartMarker,
  finishMarker: FinishMarker
};

export default icons;
