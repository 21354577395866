/**
 * Сервис для работы с пользователями
 */

import axios from 'axios';

export interface IFilterParams {
  fullTextSearch?: string;
}

const url = '/position';

const PositionsService = {
  getUsers: (params: IFilterParams = {}) => axios.get(`${url}/finduser`, { params })
};

export default PositionsService;
