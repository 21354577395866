import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MtsRoot } from '@mts-ds/components-react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TopIconProvider from '@top-ui/core/lib/TopIcon/TopIconProvider';
import { TopCssBaseline } from '@top-ui/core';

import 'moment/locale/ru';

import icons from './assets/icons';
import Root from './routes/Root';
import { store } from './store';
import theme from './theme';

const App = () => {
  return (
    <Provider store={store}>
      <MtsRoot>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <TopCssBaseline />
            <TopIconProvider value={icons}>
              <BrowserRouter>
                <Root />
              </BrowserRouter>
            </TopIconProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </MtsRoot>
    </Provider>
  );
};

export default App;
