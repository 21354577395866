import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { Dispatch, iRootState } from '../../../../../store';
import { guids, paths } from '../../../../Root/paths';

import Typography from '../../../../../components_/Typography';

import DrawerContentOffice from '../../../../../components_/Drawer/DrawerContentOffice';

import Item, { IProps as IList } from './Item';
import Button from '../../../../../components_/Fields/Button';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import DrawerSticky from '../../../../../components/Drawer/DrawerSticky';
import { useHistory } from 'react-router-dom';
import isSatisfied from '../../../../../utils/isSatisfied';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    marginTop: spacing(6)
  },
  spacing: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(3)
    }
  },
  button: {
    width: '100%',
    padding: spacing(2, 4, 2)
  }
}));

const mapState = (state: iRootState) => ({
  isLoadingOffice: state.loading.effects.infrastructureMap.getMap,
  office: state.infrastructureOffices.item,
  usersTotal: state.infrastructureMain.usersTotal,
  usersCount: state.infrastructureUsers.users.length,
  areasTotal: state.infrastructureMap.map.areas.length,
  beaconsTotal: state.infrastructureMap.map.beacons.length,
  camerasTotal: state.infrastructureMap.map.cameras.length,
  layers: state.infrastructureMap.layers,
  rights: state.securable.data,
  isCamUsed: state.admin.isCamUsed
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getUsersTotal: d.infrastructureMain.getUsersTotal,
    resetMap: d.infrastructureMap.resetMap,
    resetPlanImg: d.infrastructureMap.resetPlanImg,
    showUsers: d.infrastructureMap.showUsers,
    showAreas: d.infrastructureMap.showAreas,
    showInfrastructure: d.infrastructureMap.showInfrastructure,
    showHeatMap: d.infrastructureMap.showHeatMap,
    showCameras: d.infrastructureMap.showCameras
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Main: FC<IProps> = ({
  getUsersTotal,
  showUsers,
  showAreas,
  showInfrastructure,
  showHeatMap,
  showCameras,
  isLoadingOffice,
  office,
  usersTotal,
  usersCount,
  areasTotal,
  beaconsTotal,
  camerasTotal,
  layers,
  resetMap,
  rights,
  isCamUsed,
  resetPlanImg
}) => {
  const { isUsers, isAreas, isInfrastructure, isHeatMap, isCameras } = layers;

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getUsersTotal();
  }, []);

  const list: IList[] = [
    {
      iconName: 'users',
      title: 'Сотрудники',
      count: usersCount,
      total: usersTotal,
      checked: isUsers,
      handle: showUsers,
      link: paths.map.office.users.root,
      disabled: false,
      visible: true,
      isLoading: isLoadingOffice
    },
    {
      iconName: 'areas',
      title: 'Зоны',
      total: areasTotal,
      checked: isAreas,
      handle: showAreas,
      link: paths.map.office.areas.root,
      disabled: false,
      visible: true,
      isLoading: isLoadingOffice
    },
    {
      iconName: 'infrastructure',
      title: 'Инфраструктура',
      total: beaconsTotal,
      checked: isInfrastructure,
      handle: showInfrastructure,
      link: paths.map.office.infrastructure.root,
      disabled: !isSatisfied(
        { ...{ uid: '3bb26d38-127b-42b2-9b61-ade0b3b3e8a5' }, ...{ action: 'Write' } },
        rights
      ),
      visible: isSatisfied(
        { ...{ uid: '3bb26d38-127b-42b2-9b61-ade0b3b3e8a5' }, ...{ action: 'Read' } },
        rights
      ),
      isLoading: isLoadingOffice
    },
    // {
    //   iconName: 'heatMap',
    //   title: 'Тепловая карта',
    //   info: 'Области концентрации сотрудников на территории помещения',
    //   checked: isHeatMap,
    //   handle: showHeatMap,
    //   isLoading: isLoadingOffice
    // }
    {
      iconName: 'camera',
      title: 'Камеры',
      total: camerasTotal,
      checked: isCameras,
      handle: showCameras,
      link: paths.map.office.cameras.root,
      disabled: false,
      visible: isCamUsed,
      isLoading: isLoadingOffice
    }
  ];

  const checkAccess = isSatisfied(
    { ...{ uid: guids.map.office.plan.create }, ...{ action: 'Visibility' } },
    rights
  );

  const handleActionClick = () => {
    history.push(paths.map.office.plan.create);
    resetMap();
    resetPlanImg();
    // setMarkerCreateMode();
  };

  return (
    <>
      <Grid className={classes.spacing}>
        {/*<DrawerContentCompany isChange />*/}
        <DrawerContentOffice office={office} isLoading={isLoadingOffice} />
        <Typography variant="p1Bold" className={classes.title}>
          Показать на плане
        </Typography>
      </Grid>
      {list.map((item, index) => (
        <Item key={index} {...item} />
      ))}
      {checkAccess && (
        <DrawerSticky>
          <Button
            icon="plus"
            color={'secondary' as ButtonColors}
            className={classes.button}
            onClick={handleActionClick}
          >
            Добавить новый план
          </Button>
        </DrawerSticky>
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Main);
