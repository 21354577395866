import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsBadge, MtsDivider } from '@mts-ds/components-react';

import { IUserMap, INotificationClass } from '../../../models/types';
import getFullName from '../../../utils/getFullName';
import { getUserStatuses } from '../utils';

import Typography from '../../../components_/Typography';
import Icon from '../../../components_/Icon';
import NotificationIcon from '../../../components_/Notification/NotificationIcon';
import MarkerTooltip from './components/MarkerTooltip';
import BatteryIcon from '../../../components_/Icon/icons/BatteryIcon';
import moment from 'moment';

const useStyles = makeStyles(({ spacing, getColor }) => ({
  content: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  },
  firstState: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  otherStates: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  },
  label: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  pulseLabel: {
    color: getColor('icon-secondary')
  }
}));

enum HealthTypes {
  PULSE = 'PULSE',
  DEVICE_CHARGE = 'DEVICE_CHARGE',
  DEVICE_IS_CHARGING = 'DEVICE_IS_CHARGING'
}

interface IProps {
  user: IUserMap;
}

const UserMarkerListItemTooltip: FC<IProps> = ({ user }) => {
  const { title, tabularNumber, trackInfo, charge, position } = user;
  const { states = [], protAssigned = [], protChecked = [], measurements = [] } = trackInfo || {};
  const { precent: precentCharge = '--' } = charge || {};

  // const testStatuses = [
  //   {
  //     ntfy: {
  //       class: 'NotificationImmobilized',
  //       classFriendly: 'Обездвижен',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   },
  //   {
  //     ntfy: {
  //       class: 'NotificationInformational',
  //       classFriendly: 'Информационное сообщение',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   },
  //   {
  //     ntfy: {
  //       class: 'NotificationForgottenProtection',
  //       classFriendly: 'Забытое СИЗ',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   },
  //   {
  //     ntfy: {
  //       class: 'NotificationWorkEnd',
  //       classFriendly: 'Окончание рабочей смены',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   },
  //   {
  //     ntfy: {
  //       class: 'NotificationEnteringDangerZone',
  //       classFriendly: 'Вхождение в опасную зону',
  //       time: '2021-12-20T12:32:54.561258+03:00'
  //     }
  //   }
  // ];

  const classes = useStyles();

  const [firstState, ...otherStates] = getUserStatuses(states as any);
  const isFirstState = Boolean(firstState);
  const isOtherStates = Boolean(otherStates.length);

  const fullName = getFullName(user);
  const description = `${title} , #${tabularNumber}`;
  const { Value: pulse = '--' } = measurements.find(({ Type }) => Type === HealthTypes.PULSE) || {};
  // const { Value: charge = '--' } =
  //   measurements.find(({ Type }) => Type === HealthTypes.DEVICE_CHARGE) || {};
  const protLabel = `${protChecked.length}/${protAssigned.length}`;

  return (
    <MarkerTooltip>
      <Grid className={classes.content}>
        <MtsBadge size="s">Сотрудник</MtsBadge>

        {isFirstState && (
          <Grid container alignItems="center" wrap="nowrap" className={classes.firstState}>
            <Grid item>
              <NotificationIcon
                notificationClass={firstState.ntfy.class as INotificationClass}
                size="m"
              />
            </Grid>
            <Grid item>
              <Typography variant="p3Medium">{firstState.ntfy.classFriendly}</Typography>
            </Grid>
          </Grid>
        )}

        {isOtherStates && (
          <Grid container className={classes.otherStates}>
            {otherStates.map(({ ntfy: { class: classId } }) => (
              <NotificationIcon
                key={classId}
                notificationClass={classId as INotificationClass}
                size="s"
              />
            ))}
          </Grid>
        )}

        {(isFirstState || isOtherStates) && <MtsDivider />}

        <Typography variant="p2Bold">{fullName}</Typography>

        <Typography variant="p3Regular" color="text-secondary">
          {description}
        </Typography>

        <Grid container spacing={3} wrap="nowrap" justify="space-between" alignItems="center">
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center" className={classes.label}>
              <Icon name="heart" size="s" color="constant-orange-normal" />
              <Typography variant="p3Medium">
                {pulse} <span className={classes.pulseLabel}>уд./мин</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center" className={classes.label}>
              <BatteryIcon charge={Number(precentCharge)} />
              <Typography variant="p3Medium">{precentCharge}%</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center" className={classes.label}>
              <Typography variant="p2Medium">СИЗ</Typography>
              <MtsBadge size="s">{protLabel}</MtsBadge>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="p3Regular" color="text-secondary">
          {moment(position.timeEnd).format('L LTS')}
        </Typography>
      </Grid>
    </MarkerTooltip>
  );
};

export default UserMarkerListItemTooltip;
