import React, { FC } from 'react';

import { IDepartament } from '../../../../models/types';

import Table from '../../../../components_/Table';
import TableColLink from '../../../../components_/Table/TableColLink';
import TableColText from '../../../../components_/Table/TableColText';
import TableActionButton from '../../../../components_/Table/TableActionButton';
import TableActionGroup from '../../../../components_/Table/TableActionGroup';

export interface IProps {
  list: IDepartament[];
  onClickEdit: any;
  onClickDelete: any;
}

const List: FC<IProps> = ({ list, onClickEdit, onClickDelete }) => {
  const rows = list.map(item => ({
    items: {
      name: (
        <TableColText onClick={onClickEdit(item)}>
          <TableColLink>{item.name}</TableColLink>
        </TableColText>
      ),
      leadFio: <TableColText>{item.leadFio}</TableColText>,
      length: <TableColText>{item.employees.length}</TableColText>,
      actions: (
        <TableActionGroup>
          <TableActionButton icon="edit" tooltip="Редактировать" onClick={onClickEdit(item)} />
          <TableActionButton icon="bin" tooltip="Удалить" onClick={onClickDelete(item)} />
        </TableActionGroup>
      )
    }
  }));

  const cols = [
    {
      title: 'Название подразделения',
      field: 'name'
    },
    {
      title: 'Руководитель',
      field: 'leadFio'
    },
    {
      title: 'Количество сотрудников',
      field: 'length'
    },
    {
      title: '',
      field: 'actions'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
